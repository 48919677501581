<template>
  <!-- 原单 -->
  <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
    <OrderHead :data="order" />
    <van-cell title="订单号" :value="order.orderNo" class="auto-cell" />
    <van-cell title="下单时间" :value="order.createTime" />
    <van-cell title="订单状态" :value="order.bz1" :value-class="order.bz1|btnType" />
    <van-cell title="订单总额" :value="'￥'+order.totalPrice" value-class="red" />
    <a :href="'tel:'+phone">
      <van-cell title="服务商电话" v-show="phone">
        <template #title>
          客服
          <Logo :corp="order.corp" />
        </template>
        <template #default>
          <van-icon name="phone" class="van-cell__right-icon icon-btn blue" />
        </template>
      </van-cell>
    </a>

    <div class="gap"></div>

    <van-cell title="房间类型" :value="order.roomTypeName" />
    <van-cell title="到店日期" :value="order.arrivalDate|comverTime('YYYY-MM-DD')" />
    <van-cell title="离店日期" :value="order.departureDate|comverTime('YYYY-MM-DD')" />

    <div class="gap"></div>

    <van-panel title="人员信息" class="peoList">
      <van-cell v-for="(item,i) in showPeoList" :key="i" :label="'预留手机号：'+item.mobile" v-if="item">
        <template #title>
          <div class="left-cont">
            <van-icon name="stop" color="#44b9fa" />
            <span class="custom-title">{{item.name}}</span>
          </div>
        </template>
        <template #right-icon></template>
      </van-cell>
    </van-panel>

    <div class="gap"></div>
  </van-pull-refresh>
</template>

<script>
import { mapGetters } from "vuex";
import Logo from "@/components/Logo";
import { hotelOrderReload } from "@/api/hotel";
import OrderHead from "./components/OrderHead";
import { fetchPhone } from "@/common/config.js";
import { getStatusColor } from "@/services/hotel.js";
export default {
  computed: {
    ...mapGetters(["orderItem"]),
    showPeoList: function () {
      let result = this.peoList || [];
      for (let i = 0; i < result.length; i++) {
        const peo1 = result[i];
        if (peo1)
          for (let j = i + 1; j < result.length; j++) {
            const peo2 = result[j];
            if (peo2 && peo1.roomNo && peo1.roomNo == peo2.roomNo) {
              peo1.name += "、" + peo2.name;
              delete result[j];
            }
          }
      }
      return result;
    },
  },
  components: { OrderHead, Logo },
  data() {
    return {
      phone: "", //服务商电话
      isLoading: false, // 刷新
      canRefresh: true, // 可否刷新
      order: {}, //订单信息
      peoList: [], // 人员列表
      show: false, // 退订原因开关
    };
  },
  filters: {
    btnType: function (data) {
      return getStatusColor(data);
    },
  },
  methods: {
    // 下拉刷新
    onRefresh() {
      setTimeout(() => {
        this.fetchData(false);
      }, 500);
    },
    // ajax 获取数据
    fetchData(f = true) {
      this.phone = fetchPhone(this.orderItem.corp);
      if (f) this.canRefresh = true;
      if (this.canRefresh)
        hotelOrderReload({
          corp: this.orderItem.corp,
          orderNo: this.$route.query.id,
          outOrderNo: this.orderItem.outOrderNo,
        }).then((response) => {
          this.order = response.order;
          this.peoList = response.orderItem;
          this.isLoading = false;
          this.canRefresh = false;
          setTimeout(() => {
            this.canRefresh = true;
          }, 30 * 1000);
        });
      else {
        this.$notify({
          type: "warning",
          message: "请勿频繁刷新，请30秒后再次尝试。",
        });
        this.isLoading = false;
      }
    },
  },
  activated() {
    this.peoList = [];
    this.order = {};
    this.fetchData();
  },
};
</script>

<style lang="less" scoped>
@import '../../styles/theme.less';
.roomNumber {
  color: @gray-6;
  display: inline-block;
  border: 1px solid lighten(@blue, 10%);
  padding: 2px 11px;
  line-height: 16px;
  height: 16px;
  border-radius: 50%;
  box-shadow: 4px 4px 2px 0 lighten(@blue, 10%);
}
</style>

<style lang="less">
@import '../../styles/theme.less';
.peoList .van-panel__header-value {
  color: @gray-6;
}
</style>